import { filter } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Divider
} from '@mui/material';
// components
import queryString from 'query-string';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { MailListHead, MailListToolbar, MailMoreMenu } from '../sections/@dashboard/mail';
import appConfig from '../appConfig';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: '通知先メールアドレス', alignRight: false },
  { id: '', label: '編集', alignRight: true }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Mail() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deliveredEmails, setDeliveredEmails] = useState([]);
  const [prevDeliveredEmails, setPrevDeliveredEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [registOpen, setRegistOpen] = useState(false);
  const inputMailRef = useRef(null);
  const [defaultMailValue, setDefaultMailValue] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const { search } = useLocation();
  const query3 = queryString.parse(search);
  const biblleId = query3.biblleId.replace(/:/g, '_');

  const navigate = useNavigate();

  const BASE_URL = `${appConfig.API_BASE_URL}/mails`;

  useEffect(() => {
    console.log(query3);
    console.log(biblleId);
    const fetchData = async () => {
      const res = await fetch(`${BASE_URL}/${biblleId}`, {
        method: 'GET',
        headers: new Headers({ 'Content-type': 'application/json' })
      });
      const json = await res.json();
      console.log(json);
      const list = [];
      json.forEach((element) => {
        console.log(element);
        console.log(element.deliveredEmails.deliveredEmails);
        const mails = element.deliveredEmails.deliveredEmails;
        console.log(mails);
        mails.forEach((mail) => {
          console.log(mail);
          const data = {};
          data.deliveredEmail = mail.deliveredEmail;
          list.push(data);
        });
        console.log(list);
        setDeliveredEmails(list);
        // 前回のメールリスト保存用
        const prevTemp = Array.from(list);
        setPrevDeliveredEmails(prevTemp);
      });
    };
    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deliveredEmails.map((n) => n.deliveredEmail);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleAddAddress = () => {
    setDefaultMailValue('');
    setOpen(true);
  };

  const handleDelAddress = (id) => {
    const delAddrss = deliveredEmails[id].deliveredEmail;
    setDeliveredEmails(
      deliveredEmails.filter((deliveredEmail, index) => deliveredEmail.deliveredEmail !== delAddrss)
    );
  };

  const handleEditAddress = (id) => {
    const editAddress = deliveredEmails[id].deliveredEmail;
    setDefaultMailValue(editAddress);
    setEditIndex(id);
    setOpen(true);
  };

  const handleClose = () => {
    if (inputMailRef.current.value.length === 0) {
      return;
    }
    // eslint-disable-next-line no-control-regex
    if (!inputMailRef.current.value.match(/.+@.+\..+/)) {
      alert('不正なメールアドレスです');
      return;
    }
    let index = 0;
    index = deliveredEmails.findIndex((e) => e.deliveredEmail === inputMailRef.current.value);
    if (index !== -1) {
      alert('同一メールアドレスの登録が既に存在します');
      return;
    }

    const data = {};
    data.deliveredEmail = inputMailRef.current.value;
    if (editIndex === -1) {
      console.log('新規？');
      deliveredEmails.push(data);
      console.log(`deliveredEmails: ${deliveredEmails}`);
    } else {
      deliveredEmails.splice(editIndex, 1, data);
      console.log(`deliveredEmails: ${deliveredEmails}`);
      setEditIndex(-1);
    }

    setOpen(false);
  };

  const handleRegistClose = () => {
    setRegistOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRegister = async () => {
    const data = {};
    const emails = [];
    const emailsJson = {};

    data.biblleTagName = query3.biblleTagName;
    data.telno = query3.telno;
    data.biblleId = biblleId;
    deliveredEmails.forEach((elem) => {
      const email = {};
      email.deliveredEmail = elem.deliveredEmail;
      emails.push(email);
    });
    emailsJson.deliveredEmails = emails;
    data.deliveredEmails = emailsJson;

    const params = {
      method: 'PUT',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };

    const response = await fetch(BASE_URL, params)
      .then((response) => {
        if (!response.ok) {
          console.error('response.ok:', response.ok);
          console.error('esponse.status:', response.status);
          console.error('esponse.statusText:', response.statusText);
          throw new Error(response.statusText);
        }
        // 成功時の処理
        console.log(`前回値: ${prevDeliveredEmails}`);
        console.log(`表示値: ${deliveredEmails}`);
        const prevTemp = Array.from(deliveredEmails);
        setPrevDeliveredEmails(prevTemp);
      })
      .catch((error) => {
        // ネットワークエラーでも !response.ok でもここで処理できる
        console.error('エラーが発生しました', error);
      });
    setRegistOpen(true);
  };

  const handleBack = () => {
    console.log(query3.telno);
    navigate('/dashboard/app', { state: { telno: query3.telno } }, { replace: true });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deliveredEmails.length) : 0;

  const filteredUsers = applySortFilter(deliveredEmails, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="世田谷区みまもり通知メール設定">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            [ {query3.tagMinor} : {query3.biblleTagName} ] 通知先メールアドレス編集
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography gutterBottom>
            アドレス設定後、最下部の「登録」ボタンを押すと設定内容が更新されます。
          </Typography>
        </Stack>

        <Card>
          <MailListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onAddMail={handleAddAddress}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 50 }}>
              <Table>
                <MailListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={deliveredEmails.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {deliveredEmails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, idx) => {
                      // const { id, deliveredEmail, isAuthenticated } = row;
                      const { id, deliveredEmail } = row;
                      const isItemSelected = selected.indexOf(deliveredEmail) !== -1;
                      return (
                        <TableRow
                          hover
                          key={idx}
                          selected={isItemSelected}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle">{deliveredEmail}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <MailMoreMenu
                              id={idx}
                              name={deliveredEmail}
                              onDelMail={handleDelAddress}
                              onEditMail={handleEditAddress}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            divider={<Divider orientation="vertical" flexItem />}
            mb={1}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              startIcon={<Iconify icon="ri:arrow-go-back-fill" />}
            >
              もどる
            </Button>
            <Button
              variant="contained"
              onClick={handleRegister}
              startIcon={<Iconify icon="ri:checkbox-circle-fill" />}
            >
              登録
            </Button>
          </Stack>
        </Card>
      </Container>
      <Dialog
        open={registOpen}
        onClose={handleRegistClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">通知先メールアドレスの情報を更新しました</DialogTitle>
        <DialogActions>
          <Button onClick={handleRegistClose} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>通知先アドレスの追加</DialogTitle>
        <DialogContent>
          <DialogContentText>
            検知した時にお知らせするメールアドレスを入力してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Eメールアドレス"
            type="email"
            fullWidth
            variant="standard"
            defaultValue={defaultMailValue}
            inputRef={inputMailRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleClose}>追加</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
