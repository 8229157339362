import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, Button } from '@mui/material';
// component
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

// ----------------------------------------------------------------------

MailListToolbar.propTypes = {
  numSelected: PropTypes.number,
  onAddMail: PropTypes.func
};

export default function MailListToolbar({ numSelected, onAddMail }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography component="div" variant="subtitle1" />
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onAddMail}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          onClick={onAddMail}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          通知先の追加
        </Button>
      )}
    </RootStyle>
  );
}
