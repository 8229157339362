import { useState, useEffect } from 'react';
import { Link as useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { BiblleListHead, BiblleMoreMenu } from '../sections/@dashboard/biblle';

import appConfig from '../appConfig';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'tagMinor', label: 'タグマイナーID', alignRight: false },
  { id: 'biblleTagName', label: 'タグ名', alignRight: false },
  { id: '', label: '通知先編集', alignRight: true }
];

// ----------------------------------------------------------------------
export default function Biblle() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  /** 画面パラメータ */
  const [data, setData] = useState({});
  const [isPageLoading, setPageLoading] = useState(true);
  const [biblles, setBiblles] = useState({});
  const [isBiblleNotFound, setIsBiblleNotFound] = useState(false);
  const [emptyRows, setEmptyRows] = useState(0);

  const location = useLocation();
  // let telno = null;

  console.log(location);
  // 前画面から電話番号を受け取り
  // location.state が nullだったら /login に遷移（初期画面が/dashboard/appになるため）
  if (location.state == null) {
    console.log('location.state null check');
    window.location.href = '/login';
  } else {
    // telno = location.state;
    // console.log(telno);
    console.log(location.state);
  }

  const BASE_URL = appConfig.API_BASE_URL;

  useEffect(() => {
    setPageLoading(true);
    const uuid = '00000000A2721001B000001C4D981271';
    const tagMajor = 1001;
    const { telno } = location.state;
    console.log(telno);

    const fetchData = async () => {
      const res = await fetch(`${BASE_URL}/tags/${telno}`, {
        method: 'GET',
        headers: new Headers({ 'Content-type': 'application/json' })
      });
      const json = await res.json();
      console.log(json);
      const tempBiblles = [];
      json.forEach((element) => {
        element.biblleId = `${uuid}_${String(tagMajor)}_${String(element.tagMinor)}`;
        console.log(element);
        tempBiblles.push(element);
      });
      console.log(tempBiblles);
      setBiblles(tempBiblles);
      console.log(biblles);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tempBiblles.length) : 0);
      setIsBiblleNotFound(tempBiblles.length === 0);
      setPageLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Page title="世田谷区 みまもり通知メール設定">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            世田谷区みまもり通知サービス
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography gutterBottom>
            お申し込み頂いたタグが表示されます。（お申し込みから登録までに少し時間がかかる場合があります）
          </Typography>
        </Stack>

        {isPageLoading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <BiblleListHead
                    headLabel={TABLE_HEAD}
                    rowCount={biblles.length}
                    numSelected={selected.length}
                  />
                  <TableBody>
                    {biblles
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, idx) => {
                        const { biblleId, tagMinor, biblleTagName, telno } = row;
                        return (
                          <TableRow
                            hover
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle" noWrap>
                                {tagMinor}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle" noWrap>
                                {biblleTagName}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <BiblleMoreMenu
                                biblleId={biblleId}
                                tagMinor={tagMinor}
                                biblleTagName={biblleTagName}
                                telno={telno}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell />
                      </TableRow>
                    )}
                  </TableBody>
                  {isBiblleNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="right" sx={{ py: 3 }}>
                          <SearchNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Container>
    </Page>
  );
}
