import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@mui/material/styles';
import { Button, Card, Stack, TextField, Link, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login({ onSubmit }) {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);
  const [inputValid, setInputValid] = useState(true);

  const handleChange = () => {
    if (inputRef.current) {
      const reg = /^0\d{9,10}$/;
      const valid = !reg.test(inputRef.current.value);
      console.log(valid);
      setInputError(valid);
      setInputValid(valid);
    }
  };

  const formik = useFormik({
    initialValues: {
      telno: ''
    },
    onSubmit: () => {
      console.log(inputRef.current.value);
      // biblleページに遷移(telnoを引き渡し)
      navigate('/dashboard/app', { state: { telno: inputRef.current.value } }, { replace: true });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <RootStyle title="世田谷区 みまもり通知メール設定">
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          世田谷区
          <br />
          みまもりメール配信設定
        </Typography>
        <img src="/static/illustrations/illustration_login.png" alt="login" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h3" gutterBottom>
              世田谷区
              <br />
              みまもりメール配信サービス
            </Typography>
          </Stack>
          <br />
          <Stack sx={{ mb: 5 }}>
            <Typography sx={{ color: 'text.secondary' }}>
              本サイトでは、通知先のメールアドレスの設定を行います。
            </Typography>
            <br />
            <TextField
              autoFocus
              error={inputError}
              inputProps={{ maxLength: 11, pattern: '^0d{9,10}$' }}
              inputRef={inputRef}
              id="telno"
              label="電話番号をハイフンなしで入力してください"
              variant="outlined"
              helperText={inputRef?.current?.validationMessage}
              onChange={handleChange}
              required
            />
            <br />
            <Button
              color="primary"
              variant="contained"
              disabled={inputValid}
              onClick={() => {
                handleSubmit();
              }}
            >
              配信メール設定
            </Button>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
