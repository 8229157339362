const prod = {
  API_BASE_URL: 'https://kqn9wnbo5h.execute-api.ap-northeast-1.amazonaws.com/prod'
};

const dev = {
  API_BASE_URL: 'https://3rieiwaqli.execute-api.ap-northeast-1.amazonaws.com/dev'
};

const local = {
  API_BASE_URL: 'https://3rieiwaqli.execute-api.ap-northeast-1.amazonaws.com/dev'
};

const appConfig = (() => {
  const env = process.env.REACT_APP_STAGE;
  if (!env) {
    console.log(`env: ${env}`);
    console.log(`Consider using "REACT_APP_STAGE=local/dev/prod" for specifying the environment`);
  }
  switch (env) {
    case 'prod':
      return prod;
    case 'local':
      return local;
    case 'dev':
      return dev;
    default:
      return local;
  }
})();

export default appConfig;
